import React from 'react';
import styled from 'styled-components';
import './css/Kicks.css';

const Paragragh = styled.p`
  font-size: 2em;
`;

const Kicks = (props) => {

  return (
    <div>
      <div className="container-fluid full-bg-image">
        <div className="row bg-gradient">
          <div className="col text-center">
            <div className="heading-text">The Sneaker Raffle: DEV</div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row justify-content-center"> 
          <Paragragh>Your source for all the latest sneaker raffle info:</Paragragh>
        </div>
      </div>
    </div>
  )
}

export default Kicks;
